<template>
  <div>
    <v-card class="mt-3">
      <v-card-title class="px-2">
        {{ $t('Used items') }}
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
            :placeholder="$t('wordingSearch')"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-dialog
            ref="datePicker1"
            v-model.trim="isShowDate1"
            :return-value.sync="serve_date"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model.trim="serve_date"
                outlined
                dense
                :label="$t('date')"
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append>
                  <v-tooltip
                    color="#212121"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="serve_date= ''"
                      >
                        {{ icons.mdiCalendarBlank }}
                      </v-icon>
                    </template>
                    <span>{{ $t("all_dates") }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim="serve_date"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.datePicker1.save(serve_date)"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="isShowDate1 = false"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-autocomplete
            v-model="user_id"
            :items="userList"
            :label="$t('doctor_service')"
            item-text="user_fullname"
            item-value="user_id"
            auto-select-first
            :no-data-text="$t('no_information')"
            dense
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-select
            v-model.trim="serve_status_id"
            class="set-text"
            :items="statusData"
            :label="$t('status')"
            outlined
            dense
            :item-text="$i18n.locale"
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.opd`]="{ item }">
          <span
            v-if="item.opd"
            class="primary--text font-weight-semibold"
            style="cursor: pointer"
            @click="detailOpd(item)"
          >{{
            item.opd.opd_code
          }}</span>
          <span v-else> - </span>
        </template>
        <template v-slot:[`item.serve_id`]="{ item }">
          <span
            v-if="item.serve_detail == '1'"
            style="cursor: pointer"
            class="primary--text font-weight-semibold"
            @click="detail(item.serve_id)"
          >
            <v-tooltip
              v-if="item.detail"
              color="#212121"
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  style="cursor: pointer"
                  v-bind="attrs"
                  class="primary--text font-weight-semibold"
                  v-on="on"
                  @click="detail(item.serve_id)"
                >{{ item.serve_code }}</span>
              </template>
              <span>
                <ol class="py-0 my-0 mx-0 pl-2 pr-0">
                  <li
                    v-for="(i, index) in item.detail"
                    :key="index"
                  >
                    {{ i.name }} *{{ i.amount }}
                  </li>
                </ol>
              </span>
            </v-tooltip>
            <span
              v-else
              style="cursor: pointer"
              class="primary--text font-weight-semibold"
              @click="detail(item.serve_id)"
            >{{
              item.serve_code
            }}</span>
          </span>
          <span
            v-if="item.serve_status_id == '0'"
            style="cursor: pointer"
            class="primary--text font-weight-semibold"
            @click="showCommemt(item)"
          >{{
            item.serve_code
          }}</span>
          <span
            v-if="item.serve_status_id == '1'||item.serve_status_id == '2'"
          >{{
            item.serve_code
          }}</span>
        </template>
        <template v-slot:[`item.order_id`]="{ item }">
          <span
            v-if="item.order_id != null"
            class="primary--text font-weight-semibold"
            style="cursor: pointer"
            @click="detail_receipt(item.order_id_pri)"
          >
            {{ item.order_id }}
          </span>
          <span v-else> - </span>
        </template>
        <template v-slot:[`item.serve_date`]="{ item }">
          <ConversDateTime :datetime="item.serve_date" />
        </template>
        <template v-slot:[`item.customer_fullname`]="{ item }">
          {{ item.customer_fullname }}
        </template>
        <template v-slot:[`item.opd_status_id`]="{ item }">
          <span v-if="item.opd_status_id == 0">
            <v-chip
              label
              small

              color="error"
              :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`"
            >

              {{ $t("cancel") }}
            </v-chip>
          </span>
          <span v-else>
            <v-chip
              label
              small
              color="success"
              :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`"
            >

              {{ $t("completed") }}
            </v-chip>
          </span>
        </template>
        <template v-slot:[`item.serve_status_id`]="{ item }">
          <span v-if="item.serve_status_id == '0'">
            <v-chip
              label
              small
              color="error"
              :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`"
            >
              {{ $t("cancel") }}
            </v-chip>
          </span>
          <span v-if="item.serve_status_id == 1">
            <v-chip
              label
              small
              color="warning"
              :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`"
            >
              {{ $t("waiting_service") }}
            </v-chip>
          </span>
          <span v-if="item.serve_status_id == 2">
            <v-chip
              label
              small
              color="accent"
              :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg accent--text`"
            >

              {{ $t("use_service") }}
            </v-chip>
          </span>
          <span v-if="item.serve_status_id == 3">
            <v-chip
              label
              small
              color="success"
              :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`"
            >
              {{ $t("completed") }}
            </v-chip>
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            v-if="item.serve_opd == '1'"
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                color="primary"
                :disabled="item.shop_id_customer"
                @click="serviceToCheckq(item)"
                v-on="on"
              >
                <v-icon>{{ icons.mdiAccountCheckOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("add") }} OPD</span>
          </v-tooltip>
          <v-tooltip
            v-else
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                :disabled="item.serve_use_service == '0'"
                @click="useService(item)"
                v-on="on"
              >
                <v-icon> {{ icons.mdiCheckCircleOutline }} </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("use_service") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                :disabled="item.serve_status_id != '3'"
                @click="print(item.serve_id)"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("print") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                :disabled="item.serve_edit == '0'"
                @click="userConfirmStatus=1; serveId=item.serve_id; isUserConfirm = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("hand_charge") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.serve_status_id == 1"
                icon
                color="primary"
                v-bind="attrs"
                :disabled="item.serve_cancel == '0'"
                @click="userConfirmStatus=2; serveId=item.serve_id; isUserConfirm = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
              <v-btn
                v-else
                icon
                color="primary"
                v-bind="attrs"
                :disabled="item.serve_cancel == '0'"
                @click="userConfirmStatus=2; serveId=item.serve_id; isUserConfirm = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("cancel") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <v-dialog
      v-model="dialogShowComment"
      max-width="400"
    >
      <v-card>
        <v-card-title class="py-1">
          <span>{{ $t("note") }}</span>
          <v-spacer></v-spacer>
          <span>
            <v-btn
              color="error"
              icon
              @click="dialogShowComment = false"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </span>
        </v-card-title>
        <v-card-text align="center">
          <span v-html="serve_comment"></span>
        </v-card-text>
      </v-card>
    </v-dialog>
    <EditFee
      :id="serveId"
      v-model="isEditFee"
    />
    <UserConfirm
      v-model="isUserConfirm"
      @onSend="userConfirmStatus==1?isEditFee = true:isCancel = true"
    />
    <ServiceCancel
      :id="serveId"
      v-model="isCancel"
      @onUpdate="fetchDataTable"
    />
    <DialogAddCheckQueue
      v-model="dialogAddQ"
      :customer-id="dataForCheckq.customer_id_pri"
      :service-id="dataForCheckq.serve_id"
    />
  </div>
</template>

<script>
import {
  mdiCalendarBlank, mdiPencilOutline, mdiPrinter, mdiCheckCircleOutline, mdiClose, mdiReceipt, mdiFlaskOutline, mdiAccountCheckOutline,
} from '@mdi/js'
import useServiceRoom from '@/views/queueManagement/useServiceRoom'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'
import EditFee from '@/views/queueManagement/EditFee.vue'
import UserConfirm from '@/components/basicComponents/UserConfirm.vue'
import ServiceCancel from '@/views/queueManagement/ServiceCancel.vue'
import DialogAddCheckQueue from '@/views/queueManagement/DialogAddCheckQueueService.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    Pagination,
    ConversDateTime,
    EditFee,
    UserConfirm,
    DialogAddCheckQueue,
    ServiceCancel,
  },

  setup() {
    return {
      ...useServiceRoom(),

      icons: {
        mdiAccountCheckOutline,
        mdiCalendarBlank,
        mdiPencilOutline,
        mdiPrinter,
        mdiCheckCircleOutline,
        mdiClose,
        mdiReceipt,
        mdiFlaskOutline,
      },
    }
  },

}
</script>
